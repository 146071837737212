
  import { Component, Watch } from 'vue-property-decorator'
  import Cell from '@/components/dataTables/cell/cell'
  import { Stock } from '@/entities/sales'

@Component
  export default class StockButtonCell extends Cell {
  stock: Stock = null

  // Methods
  triggerOptions (item) {
    const { options } = this
    if (!options?.trigger || !item) return

    const construct = {
      constructor: {
        name: 'StockPublish',
      },
      id: item.id,
    }
    return options.trigger(construct)
  }

  // Getters

  // Watchers
  @Watch('item', { immediate: true, deep: true })
  async onItemChange (val) {
    const stock = (await this.fetchData({
      query: { name: 'find', model: 'Stock' },
      filter: { enablement: { inspection: { negotiation: { purchase_order: { id: { _eq: val.id } } } } } },
      force: true,
    }))[0]
    if (stock) {
      this.stock = stock
    }
  }
  }
